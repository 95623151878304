<template>
    <div>
        <button type="submit" class="btn btn-danger btn-sm mr-3" @click="$router.push('/apply')">Jetzt kennenlernen</button>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped></style>
